import * as Sentry from '@sentry/browser'

export function withSentry (promise) {
  return Promise.resolve(promise)
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err)
      Sentry.captureException(err)
    })
}
