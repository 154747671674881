export const COLLECTIONS_ENUM = {
  OWNERS: 'OWNERS',
  SCENARIO_PUBLIC: 'SCENARIO_PUBLIC',
  SCENARIO_RESPONSES: 'SCENARIO_RESPONSES',
  RESPONSES: 'RESPONSES',
  CALENDAR: 'CALENDAR',
  SCHEDULED_SLOTS: 'SCHEDULED_SLOTS',
  USERS: 'USERS',
  QR_REDIRECTS: 'QR_REDIRECTS',
  INTERVIEWS: 'INTERVIEWS',
  CAMPAIGN_ID_LABEL_MAPPINGS: 'CAMPAIGN_ID_LABEL_MAPPINGS',
  SHRINKER: 'SHRINKER',
  LANDING_PAGE_SITES: 'LANDING_PAGE_SITES',
  LANDING_PAGE_DEPLOYS: 'LANDING_PAGE_DEPLOYS',
  LANDING_PAGE_PREVIEWS: 'LANDING_PAGE_PREVIEWS',
  QR_CODES: 'QR_CODES'
}

export const VIDEO_STORAGE_KEY = 'INTERVIEW_VIDEOS'
export const IMAGE_STORAGE_KEY = 'SITE_IMAGES'

export const ACCOUNT_LOGIN_TYPES = {
  phone: 'phone', // How to re-link phone?
  facebook: 'facebook.com',
  google: 'google.com',
  // https://firebase.google.com/docs/auth/web/account-linking#link-email-address-and-password-credentials-to-a-user-account
  email: 'password'
}
