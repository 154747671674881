import { SchedulerlyClient } from '@appsocially/schedulerly-client'

const STAGES = {
  'demo': 'stage',
  'prod': 'production',
  'epark': 'production',
  'stage': 'stage'
}

export const schedulerlyClient = new SchedulerlyClient({
  stage: STAGES[process.env.VUE_APP_STAGE] ? STAGES[process.env.VUE_APP_STAGE] : process.env.VUE_APP_STAGE,
  app: 'truffle'
})
