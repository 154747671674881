import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

export const isOnBoarded = async ({ ownerId }) => {
  if (!ownerId) {
    return false
  } else {
    const owner = await firestore
      .collection(COLLECTIONS_ENUM.OWNERS)
      .doc(ownerId)
      .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
      .doc(process.env.VUE_APP_RECRUIT_SCENARIO_ID)
      .get()

    return owner.exists
  }
}
