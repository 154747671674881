import { firebaseFunctions, firebase } from './firebase'
// import store from '@/store'
// import { withCache } from './cache'

const functionRouter = funcName => {
  const callableInstance = firebaseFunctions.httpsCallable('entryFunc')
  return payload => callableInstance({ funcName, payload })
}

const vercelApiWrapper = async (funcName, data, baseRoute = 'auth') => {
  const route = `/api/${baseRoute}/${funcName}`
  const currentUser = firebase.auth().currentUser
  if (currentUser) {
    const idToken = await currentUser.getIdToken()
    const response = await fetch(route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: idToken
      },
      body: data ? JSON.stringify(data) : null
    })

    if (response.ok) {
      if (response.status === 204) {
        return null
      } else {
        return response.json()
      }
    } else {
      const { error } = await response.json()
      throw new Error(error)
    }
  } else {
    throw new Error(`Vercel functions error: (${route}) cannot call functions without being logged in.`)
  }
}

// const sendFinalHiringOnboardingInformation = () => vercelApiWrapper('sendFinalHiringOnboardingInformation')

const getQRCodeCounts = data => vercelApiWrapper('getQRCodeCounts', data)
const getQRCodeLabel = data => vercelApiWrapper('getQRCodeLabel', data)

const orderQRCodes = data => vercelApiWrapper('orderQRCodes', data)
const getQRCodes = data => vercelApiWrapper('getQRCodes', data)
// const addQRCode = data => vercelApiWrapper('addQRCode', data)
const getQRCode = data => vercelApiWrapper('getQRCode', data)
const addQRCode = functionRouter('addQRCode')
const updateQRCode = data => vercelApiWrapper('updateQRCode', data)
const deleteQRCode = data => vercelApiWrapper('deleteQRCode', data)

const updateUserPaymentSource = () => vercelApiWrapper('updateUserPaymentSource')

const interviewAppointmentCreated = data => vercelApiWrapper('interviewAppointmentCreated', data)

// const createInterview = data => vercelApiWrapper('createInterview', data)
const createInterview = functionRouter('createInterview')

const cancelInterview = data => vercelApiWrapper('cancelInterview', data)

const createUserStripeSubscription = functionRouter(
  'createUserStripeSubscription'
)

const saveIOSRegistrationToken = functionRouter(
  'saveIOSRegistrationToken'
)

const notifyOfMeeting = functionRouter(
  'notifyOfMeeting'
)

const stripeCouponHandler = functionRouter(
  'stripeCouponHandler'
)

// const realtimeCampaignNotification = data => vercelApiWrapper('realtimeCampaignNotification', data, 'open')
const realtimeCampaignNotification = functionRouter(
  'realtimeCampaignNotification'
)

const associateOwnerWithRedirectId = data => vercelApiWrapper('associateOwnerWithRedirectId', data)

const getScheduledSlot = functionRouter(
  'getScheduledSlot'
)

const cancelScheduledSlot = functionRouter(
  'cancelScheduledSlot'
)

const confirmScheduledSlot = functionRouter(
  'confirmScheduledSlot'
)

const addLabelToCampaignId = data => vercelApiWrapper('addLabelToCampaignId', data)

const updateUserImage = data => vercelApiWrapper('updateUserImage', data)
// const isOnBoarded = functionRouter(
//   'isOnBoarded'
// )

// const isHiringActive = functionRouter(
//   'isHiringActive'
// )

// const updateHiringActive = functionRouter(
//   'updateHiringActive'
// )

const createStaff = functionRouter(
  'createStaff'
)

const createStaffIfRequired = functionRouter(
  'createStaffIfRequired'
)

const updateStaff = functionRouter(
  'updateStaff'
)

const getAllStaffData = functionRouter(
  'getAllStaffData'
)

const getAllApplicantData = functionRouter(
  'getAllApplicantData'
)

const updateUserProfile = data => vercelApiWrapper('updateUserProfile', data)

// const getUserProfile = functionRouter(
//   'getUserProfile'
// )

const getApplicantSearchKey = () => vercelApiWrapper('getApplicantSearchKey')

const createStripeInvoiceCustomer = functionRouter(
  'createStripeInvoiceCustomer'
)

const createStripeInvoiceSubscription = functionRouter(
  'createStripeInvoiceSubscription'
)

const createStripeInvoice = functionRouter(
  'createStripeInvoice'
)

const addSentiment = data => vercelApiWrapper('addSentiment', data, 'open')
const updateSentimentTogetherWithComments = data => vercelApiWrapper('updateSentimentTogetherWithComments', data, 'open')
const getSentiments = data => vercelApiWrapper('getSentiments', data, 'open')
const getAllSentiments = data => vercelApiWrapper('getAllSentiments', data, 'open')
const getStaffComments = data => vercelApiWrapper('getStaffComments', data, 'open')

const getSuggestions = functionRouter('getSuggestions')
const readSuggestion = functionRouter('readSuggestion')
const deleteSuggestion = functionRouter('deleteSuggestion')

const getMailSubscription = functionRouter('getMailSubscription')
const updateMailSubscription = functionRouter('updateMailSubscription')

const notifyApplicantReschedule = functionRouter('notifyApplicantReschedule')

const createQuestionaireResult = functionRouter('createQuestionaireResult')

const updateQuestionaireResult = functionRouter('updateQuestionaireResult')

const getApplicantSecretKey = functionRouter('getApplicantSecretKey')

const getAllReasons = functionRouter('getAllReasons')

const getRescheduleInfo = functionRouter('getRescheduleInfo')

const rescheduleInterview = functionRouter('rescheduleInterview')

const exportApplicantsToCSV = functionRouter('exportApplicantsToCSV')

const applicantSuggestInterviewDates = functionRouter('applicantSuggestInterviewDates')

const getApplicantSuggestedDates = functionRouter('getApplicantSuggestedDates')

const confirmApplicantSuggestedDates = functionRouter('confirmApplicantSuggestedDates')

// const getFans = functionRouter('getFans')

// const sendRecruitingRestarted = functionRouter('sendRecruitingRestarted')

const removeUser = functionRouter('removeUser')

const getOwner = data => vercelApiWrapper('getOwner', data)
const getResponse = data => vercelApiWrapper('getResponse', data)
const getResponses = data => vercelApiWrapper('getResponses', data)
const updateResponse = data => vercelApiWrapper('updateResponse', data)
const deleteResponse = data => vercelApiWrapper('deleteResponse', data)

// schedulerly
// const deleteCalendarSlot = functionRouter('deleteCalendarSlot')
const generateSchedulerlyToken = functionRouter('generateSchedulerlyToken')
// const updateAttendeeWithApplicantData = functionRouter('updateAttendeeWithApplicantData')
// const openCalendarSlot = functionRouter('openCalendarSlot')
// const openCalendarSlots = functionRouter('openCalendarSlots')
// const deleteGeneratedSlot = functionRouter('deleteGeneratedSlot')
// const updateSlot = functionRouter('updateSlot')
// const getCalendarSlots = functionRouter('getCalendarSlots')
// const getCalendars = withCache(
//   `user/:id/calendars_new`,
//   functionRouter('getCalendars'),
//   {
//     ttl: 3 * 24 * 60 * 60 * 1000
//   }
// )
// const getUserScheduledSlots = functionRouter('getUserScheduledSlots')
// const getUserRule = functionRouter('getUserRule')
const getCalendarICS = functionRouter('getCalendarICS')
// const addUserRule = functionRouter('addUserRule')
// const getUserRuleAndScheduledSlots = functionRouter('getUserRuleAndScheduledSlots')

export {
  getApplicantSearchKey,
  updateUserPaymentSource,
  // sendFinalHiringOnboardingInformation,
  getQRCodeCounts,
  getQRCodeLabel,
  orderQRCodes,
  getQRCodes,
  getQRCode,
  addQRCode,
  updateQRCode,
  deleteQRCode,
  interviewAppointmentCreated,
  createInterview,
  cancelInterview,
  createUserStripeSubscription,
  saveIOSRegistrationToken,
  notifyOfMeeting,
  stripeCouponHandler,
  realtimeCampaignNotification,
  associateOwnerWithRedirectId,
  getScheduledSlot,
  cancelScheduledSlot,
  confirmScheduledSlot,
  addLabelToCampaignId,
  updateUserImage,
  createStaff,
  createStaffIfRequired,
  updateStaff,
  getAllStaffData,
  getAllApplicantData,
  updateUserProfile,
  createStripeInvoiceCustomer,
  createStripeInvoiceSubscription,
  createStripeInvoice,
  addSentiment,
  updateSentimentTogetherWithComments,
  getSentiments,
  getAllSentiments,
  getStaffComments,
  getSuggestions,
  readSuggestion,
  deleteSuggestion,
  createQuestionaireResult,
  updateQuestionaireResult,
  getMailSubscription,
  updateMailSubscription,
  getApplicantSecretKey,
  getAllReasons,
  notifyApplicantReschedule,
  getRescheduleInfo,
  rescheduleInterview,
  exportApplicantsToCSV,
  applicantSuggestInterviewDates,
  getApplicantSuggestedDates,
  confirmApplicantSuggestedDates,
  removeUser,
  getOwner,
  getResponse,
  getResponses,
  updateResponse,
  deleteResponse,
  // schedulerly
  getCalendarICS,
  generateSchedulerlyToken
}
