/* eslint-disable no-console */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
import { decodeCampaignInfo } from '@/helpers/analytics'
import { createInterview, generateSchedulerlyToken } from '@/helpers/firebase-functions'
import { schedulerlyClient } from '@/helpers/schedulerly'
import { withSentry } from '@/helpers/sentry'
import { parseDateTimeString } from '@appsocially/schedulerly-client/src/helpers/time'
import * as Sentry from '@sentry/browser'
import { addMinutes, startOfToday } from 'date-fns'

export const state = () => ({
  scheduledSlots: [],
  scheduledRules: [],
  loadedRulesAndSlots: false,
  loadingScheduledSlots: false,
  subscriptionInfo: null,
  showRescheduleConfirmation: false,
  showProposedDate: true,
  currentCalendar: null,
  calendars: [],
  slots: [],
  savedInterviewId: null,
  savedInterview: null
})

export const mutations = {
  updateCalendars (state, calendars) {
    state.calendars = [...calendars]
  },
  updateCurrentCalendar (state, calendar) {
    state.currentCalendar = calendar
  },
  removeScheduledSlot (state, slotId) {
    state.scheduledSlots = state.scheduledSlots.filter(slot => slot.id !== slotId)
  },
  replaceScheduledSlots (state, slot) {
    let index = state.scheduledSlots.findIndex(oldSlot => oldSlot.id === slot.id)
    if (index < 0) {
      return
    }
    let oldSlot = state.scheduledSlots[index]
    state.scheduledSlots = Object.assign([], state.scheduledSlots, {
      [index]: {
        ...oldSlot,
        ...slot
      }
    })
  },
  ignoreStartTime (state, { startTime, duration }) {
    state.scheduledRules = {
      ...state.scheduledRules,
      ignoredStartTimes: [
        ...state.scheduledRules.ignoredStartTimes,
        { startTime, duration }
      ]
    }
  },
  addScheduledSlots (state, slots) {
    state.scheduledSlots = state.scheduledSlots.concat(slots)
  },
  updateScheduledSlots (state, value) {
    state.scheduledSlots = value
  },
  updateLoadedRulesAndSlots (state, value) {
    state.loadedRulesAndSlots = value
  },
  updateScheduledRules (state, value) {
    state.scheduledRules = value
  },
  updateLoadingScheduledSlots (state, value) {
    state.loadingScheduledSlots = value
  },
  updateCalendarSubscriptionEnder (state, value) {
    state.calendarSubscriptionEnder = value
  },
  updateSubscriptionInfo (state, { ownerId, scenarioId }) {
    state.subscriptionInfo = { ownerId, scenarioId }
  },
  updateSavedInterviewId (state, value) {
    state.savedInterviewId = value
  },
  updateSavedInterview (state, value) {
    state.savedInterview = value
  },
  updateRescheduleConfirmation (state, value) {
    state.showRescheduleConfirmation = value
  },
  updateShowProposedDate (state, value) {
    state.showProposedDate = value
  }
}

export const actions = {
  setCalendarSubscriptionInfo ({ commit }, { ownerId, scenarioId }) {
    commit('updateLoadingScheduledSlots', false)
    commit('updateLoadedRulesAndSlots', false)
    commit('updateScheduledSlots', [])
    commit('updateSubscriptionInfo', { ownerId, scenarioId })
  },
  resetSavedInterview ({ commit }) {
    commit('updateSavedInterviewId', null)
    commit('updateSavedInterview', null)
  },
  authenticateSchedulerlyClient () {
    return schedulerlyClient.authenticate({
      onTokenRequired: () => {
        return generateSchedulerlyToken()
          .then(({ data }) => data.token)
      }
    })
  },
  skipAuthenticateSchedulerlyClient ({ state }, { ownerId }) {
    schedulerlyClient.skipAuth({
      userId: ownerId
    })
  },
  async addSlots ({ commit, state }, { times, maxAttendees }) {
    const slots = times.map(({ from, to }) => {
      return {
        to,
        from,
        timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
        maxAttendees: maxAttendees || 1
      }
    })
    const newSlots = await withSentry(
      schedulerlyClient.openCalendarSlots({
        calendarId: state.currentCalendar.id,
        slots
      })
    )

    commit('addScheduledSlots', newSlots.map(slot => ({
      attendees: [],
      ...slot
    })))

    return newSlots
  },
  async removeSlot ({ commit, dispatch, state }, { id, startDate }) {
    if (id) {
      withSentry(schedulerlyClient.removeCalendarSlot({
        calendarId: state.currentCalendar.id,
        slotId: id
      }))
      commit('removeScheduledSlot', id)
      return
    }

    const duration = state.scheduledRules.duration || 30
    withSentry(schedulerlyClient.excludeRuleSlot({
      slots: [{
        startTime: startDate,
        duration
      }]
    }))
    commit('ignoreStartTime', {
      startTime: startDate,
      duration
    })
  },
  async getCalendars ({ commit }, { uid }) {
    return schedulerlyClient.getCalendars()
      .then(calendars => {
        console.log('getCalendars', calendars)
        commit('updateCalendars', calendars)
        commit('updateCurrentCalendar', calendars[0])
        return calendars
      })
  },
  async updateSlot (
    { commit, dispatch },
    {
      editedSlot,
      data: { start, duration, maxAttendees, startTime }
    }
  ) {
    const from = new Date(editedSlot.startISO)
    const to = addMinutes(from, parseInt(duration))

    if (!editedSlot.id) {
      // This slot has not yet been created, so we should and the new slot and then remove the
      // old slot rule
      withSentry(dispatch('removeSlot', { startDate: from }))
      let newSlots = await withSentry(dispatch('addSlots', {
        times: [{
          from,
          to
        }],
        maxAttendees: parseInt(maxAttendees)
      }))

      return {
        ...editedSlot,
        ...newSlots[0]
      }
    }

    const data = {
      from,
      to,
      maxAttendees: parseInt(maxAttendees),
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    withSentry(schedulerlyClient.updateSlot({
      slotId: editedSlot.id,
      slot: data
    }).then((data) => commit('replaceScheduledSlots', data)))

    const guessedSlot = {
      ...editedSlot,
      ...data,
      attendees: editedSlot.attendees || []
    }
    commit('replaceScheduledSlots', guessedSlot)
    return guessedSlot
  },
  replaceScheduledSlots ({ commit }, { slot }) {
    commit('replaceScheduledSlots', slot)
  },
  removeScheduledSlot ({ commit }, { slot }) {
    commit('removeScheduledSlot', slot.id)
  },
  async getScheduledSlotsAndRules ({ state, commit }, { withAttendeeData } = { withAttendeeData: true }) {
    const { loadedRulesAndSlots, subscriptionInfo: { ownerId } } = state

    /**
     * Silently update the rules and slots unless this is the first time we are getting them for this applicant
     */
    if (!loadedRulesAndSlots) {
      commit('updateLoadingScheduledSlots', true)
    }
    try {
      const { slots, rules } = await schedulerlyClient.getUserScheduledSlotsAndRules({
        startDate: startOfToday(),
        withAttendeeData
      })

      console.log('getUserScheduledSlotsAndRules', slots, rules)

      commit('updateScheduledSlots', slots)
      commit('updateScheduledRules', rules)
      commit('updateLoadedRulesAndSlots', true)
    } catch (err) {
      // eslint-disable-next-line
      console.error(
        'Error loading scheduled slots', err
      )
      Sentry.captureException(err)
    } finally {
      commit('updateLoadingScheduledSlots', false)
    }
  },
  async createInterview ({ state, commit }, { slot, applicantId, applicantProfile, isConfirmed }) {
    const { ownerId, scenarioId: ownerScenarioId } = state.subscriptionInfo
    let campaignInfo = null
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const campaignInfoStr = urlParams.get('campaignInfo')
      if (campaignInfoStr) {
        campaignInfo = decodeCampaignInfo(urlParams.get('campaignInfo'))
      }
    } catch (error) {
      console.error(error)
      campaignInfo = null
    }

    // window.gtag('event', 'impression', { 'event_category': 'created_interview', 'event_label': ownerId })
    window.gtag('event', 'created_interview', {
      'owner_id': ownerId,
      // 'owner_name': '{owner name}',
      'page_type': 'hiring_bot',
      'owner_scenario_id': ownerScenarioId,
      'campaign_id': (campaignInfo) ? campaignInfo.campaignId : null,
      'campaign_type': (campaignInfo) ? campaignInfo.campaignType : null
    })

    const { data: interview } = await createInterview({
      ownerId,
      ownerScenarioId,
      slot,
      applicantId,
      applicantProfile,
      isConfirmed
    })

    const { id: savedInterviewId } = interview
    commit('updateSavedInterviewId', savedInterviewId)
    commit('updateSavedInterview', interview)

    // XXX: standardize these dates
    let newSlot
    if (!slot.id) {
      const { isoDate, startTime, duration } = slot
      const from = parseDateTimeString(`${isoDate} ${startTime}`)
      const to = addMinutes(from, duration)
      newSlot = {
        from: from,
        to: to,
        id: interview.schedulerlySlotId,
        maxAttendees: 1,
        attendees: []
      }
      commit('addScheduledSlots', [newSlot])
      commit('ignoreStartTime', {
        startTime: from,
        duration
      })
    }

    return {
      interview,
      slot: newSlot
    }
  },
  setupdateRescheduleConfirmation ({ commit }, showConfirmation) {
    commit('updateRescheduleConfirmation', showConfirmation)
  },
  setupdateShowProposedDate ({ commit }, showProposedDate) {
    commit('updateShowProposedDate', showProposedDate)
  },
  removeAttendeeFromSlot ({ commit, state }, { attendee, slot }) {
    const oldSlotIdx = state.scheduledSlots.findIndex(({ id }) => slot.id === id)
    if (oldSlotIdx < 0) {
      return
    }

    const oldSlot = state.scheduledSlots[oldSlotIdx]
    oldSlot.attendees = oldSlot.attendees.filter(({ refId }) => refId !== attendee.refId)
    commit('replaceScheduledSlots', oldSlot)
    return oldSlot
  }

}
