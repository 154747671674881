<template>
  <v-app-bar app clipped-left>
    <v-app-bar-nav-icon
      v-if="showMenuButton"
      @click="$emit('clicked-drawer')"
    />
    <v-toolbar-title class="headline font-weight-bold"
      >{{ title }}</v-toolbar-title
    >
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import { serviceName } from '@/truffle.config'

export default {
  props: {
    enableSidebar: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: serviceName
    }
  },
  computed: {
    ...mapState('auth', ['isOnboardingDone']),
    showMenuButton () {
      return this.enableSidebar && this.isOnboardingDone
    }
  }
}
</script>

<style>
</style>
