import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

export const getUserProfile = async (ownerId) => {
  const user = await firestore
    .collection(COLLECTIONS_ENUM.USERS)
    .doc(ownerId)
    .get()

  if (!user.exists) {
    return null
  }

  const { profile } = user.data() ? user.data() : null

  return profile
}

export const updateUserProfile = async ({ ownerId, profile }) => {
  await firestore
    .collection(COLLECTIONS_ENUM.USERS)
    .doc(ownerId)
    .set({ profile }, { merge: true })
}
