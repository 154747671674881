
const stringify = require('fast-safe-stringify')

class CacheManager {
  version = '1.0.0'
  cacheName = '__cache'

  constructor ({
    cacheName
  } = {
    cacheName: '__cache'
  }) {
    this.cacheName = cacheName
    this.init()
  }

  init () {
    if (!localStorage[this.cacheName]) {
      localStorage[this.cacheName] = stringify({})
    }
    this.removeZombieCaches()
  }

  removeZombieCaches () {
    const cache = this.getCache()
    Object.keys(cache).forEach((key) => {
      const item = cache[key]
      const ttl = item.options.ttl || 3 * 24 * 60 * 60 * 1000
      // console.log(item)
      const alivePeriod = new Date().getTime() - item.options.lastSetDate
      if (alivePeriod < ttl) {
        return
      }

      // console.log('CACHE ZOMBIE KEY', key, alivePeriod, ttl)
      delete cache[key]
    })
  }

  getCache () {
    return JSON.parse(localStorage[this.cacheName])
  }

  canCache () {
    return typeof (Storage) !== 'undefined'
  }

  set (name, value, options = {}) {
    const oldCache = this.getCache()
    const newCache = stringify({
      ...oldCache,
      [name]: {
        value,
        options: {
          ttl: 3 * 24 * 60 * 60 * 1000, // 3 days
          ...options,
          lastSetDate: new Date().getTime(),
          version: this.version
        }
      }
    })
    localStorage.setItem(this.cacheName, newCache)
  }

  del (name) {
    const oldCache = this.getCache()
    delete oldCache[name]
    localStorage[this.cacheName] = stringify(oldCache)
  }

  get (name) {
    const cache = this.getCache()
    return cache[name].value
  }

  has (name) {
    const cache = this.getCache()
    return Object.keys(cache).includes(name)
  }

  clear () {
    localStorage[this.cacheName] = stringify({})
  }
}

const cacheManager = new CacheManager()

module.exports.withCache = (name, fn, options = {}) => {
  return async (...params) => {
    if (!cacheManager.canCache()) {
      // console.log(`CACHE NOTAVAILABLE: [${name}]`)
      // Fallback for older browsers
      return fn(...params)
    }

    if (cacheManager.has(name)) {
      // console.log(`CACHE HITS: [${name}]`)
      return cacheManager.get(name)
    }

    // console.log(`CACHE MISS: [${name}]`)
    const result = await fn(...params)
    cacheManager.set(name, result, options)
    return result
  }
}

module.exports.cacheManager = cacheManager
