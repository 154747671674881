import { getApplicantSearchKey } from '@/helpers/firebase-functions'

export const store = () => ({
  algoliaKey: null
})

export const mutations = {
  updateAlgoliaKey (state, value) {
    state.algoliaKey = value
  },
  reset (state) {
    state.algoliaKey = null
  }
}

export const actions = {
  async getAlgoliaKey ({ state, commit }) {
    const { algoliaKey } = state
    if (!algoliaKey) {
      const { apiKey } = await getApplicantSearchKey()
      commit('updateAlgoliaKey', apiKey)
      return apiKey
    } else {
      return algoliaKey
    }
  },
  reset ({ commit }) {
    commit('reset')
  }
}
