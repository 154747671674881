const enMessages = {
  drawer: {
    home: 'Home',
    calendar: 'Calendar',
    team: 'People',
    applicant: 'Applicant',
    staff: 'Staff',
    fan: 'Fan',
    flow: 'Flow',
    statistics: 'QR Code Statistics',
    mediaQRCode: 'QR Code for Media',
    staffRoom: 'Staff Room',
    friends: '"Refer Your Friend" Program',
    createQRCode: 'QR Code Creation',
    inside: 'Inside Restaurant',
    masterQRCode: 'Main QR Code for Hiring Staff',
    poster: 'QR Code Posters and Stickers',
    search: 'Search Engine',
    pageSettings: 'Website Page Settings',
    ownerSite: 'Owner Website',
    qrCodeURL: 'URL',
    settings: 'Settings',
    accountSettings: 'Account Settings',
    payment: 'Payment Details',
    botSettings: 'Bot Settings',
    signOut: 'Sign Out'
  },
  profile: {
    title: 'Profile',
    givenName: 'Last Name',
    firstName: 'First Name',
    email: 'Email',
    next: 'Next',
    back: 'Back (Sign out)'
  },
  calendar: {
    all: 'All',
    scheduled: 'Scheduled',
    available: 'Available',
    createMultipleSlot: 'Create Multiple Slots',
    createSingleSlot: 'Create a Slot',
    attendee: {
      add: 'Add Attendee',
      max: 'Attendees: (Max: {max})',
      actions: 'Actions'
    },
    title: {
      addSlot: 'There is already a slot during this time. Do you really want to add a new slot?',
      maxAttendee: 'You can not reduce the number of attendees, because there is already a maximum number of attendees scheduled.',
      startTime: 'You can not change this slot, because it already has a scheduled interview.',
      deleteAttendee: 'Are you sure you want to delete?',
      showDeletePrev: 'It looks like the previous slot did not work for you. Do you want to delete it?',
      export: 'Export Calendar'
    },
    table: {
      name: 'Name',
      action: 'Actions'
    },
    reschedule: {
      title: 'Please change the "interview date and time". We will send a message to the applicant requesting a change of date and time.'
    }
  },
  applicant: {
    title: 'Applicants',
    loading: 'Loading...',
    noData: 'No applicants',
    counts: 'Applicants',
    notFilled: 'No name',
    download: 'Download list of applicants (CSV)',
    details: {
      title: 'Applicant Details'
    }
  },
  fans: {
    title: 'Fans',
    send: 'Send "Hiring Restarted" Notice',
    dialog: {
      title: 'Send Notice',
      text: 'Currently, hiring is stopped. Restart hiring and send notice?'
    },
    table: {
      created: 'Registered',
      name: 'Name',
      contact: 'Email/Telno'
    }
  },
  stats: {
    title: 'QR Code Statistics',
    dialog: {
      title: 'Edit QR Code Label',
      label: 'Label'
    },
    table: {
      label: 'Label',
      id: 'ID',
      applicants: 'Applications',
      scans: 'Scans',
      deletedDateTime: 'Deleted Date Time',
      action: 'Action'
    }
  },
  media: {
    title: 'QR Code for Media',
    create: 'Create QR Code',
    createDialog: {
      title: 'Create QR Code'
    },
    updateDialog: {
      title: 'Edit QR Code'
    },
    table: {
      label: 'Label',
      id: 'ID',
      url: 'Link',
      action: 'Actions'
    },
    deleteDialog: {
      text: 'Are you sure you want to delete the QR code ({label})?'
    }
  },
  qrCode: {
    subtitle: 'Enter QR code label',
    label: 'QR Code Label',
    creating: 'Creating QR code...'
  },
  referral: {
    title: '"Refer Your Friend" Program',
    subtitle: `Use this QR code or URL when asking your employees for referrals.<br/>
    If there is an application from a referral, you will be able to identify which employee referred the applicant.<br/><br/>
    [Example Use]<br/>
    Put up a poster with the QR code in the employee resting room, send the URL to an SNS group that the employees use for shift calls, and so on, to get the employees to refer their friends<br/><br/>
    [Application Flow]<br/>
    Employee A accesses this QR code/URL ->  QR code/URL is created for Employee A -> Employee A sends the created QR code/URL to his/her friends who are interested in the interview -> Their friends apply`,
    creating: 'Creating QR code...',
    qrCodeCard: {
      title: 'QR Code for "Refer Your Friend" Program'
    },
    label: '"Refer Your Friend" Program'
  },
  master: {
    title: 'Main QR Code for Hiring Staff',
    subtitle: 'You may set the interview date and time from this QR code. Show this to the applicant.',
    creating: 'Creating QR code...',
    qrCodeCard: {
      title: 'Main QR Code for Hiring Staff'
    }
  },
  order: {
    title: 'QR Code for Posters and Stickers',
    text: 'You may order "Staff Hiring" posters and "QR Code for Staff Hiring" stickers for your store. (A limited time free offer)<br/>There may be charges depending on the content and quantity of your order. Feel free to contact us. A representative will contact you.',
    ordered: 'Posters and stickers are ordered.',
    doOrder: 'Order'
  },
  landing: {
    title: 'Website Page Settings',
    saved: 'Saved',
    updateJob_1: 'Please update your job types in',
    updateJob_2: 'before using the landing page generator.',
    store: {
      title: 'Store page',
      publicURL: 'Store public URL',
      previewURL: 'Store preview URL'
    },
    jobs: {
      title: 'Jobs Page',
      publicURL: ' public URL',
      previewURL: ' preview URL',
      pageOf: ' page'
    },
    addJob: 'Add Job',
    notFilled: 'Some fields are missing values.',
    save: 'Save and Preview',
    upload: 'Deploy Website',
    newJob: 'New Job'
  },
  url: {
    title: 'Hiring URL for Owner Site',
    subtitle: 'Use this hiring URL for your own website.',
    label: 'Owner Site'
  },
  account: {
    title: 'Account Settings',
    loginAgain: 'You must login again to modify linked accounts.',
    reauth: 'Reauthenticate (Login)',
    accountLink: 'Account Links',
    link: 'Link',
    unlink: 'Unlink',
    profile: {
      title: 'Profile',
      givenName: 'Last Name',
      firstName: 'First Name',
      email: 'Email',
      save: 'Update'
    },
    email: {
      title: 'Link Email Account',
      email: 'Email',
      password: 'password',
      password2: 'password (confirmation)'
    },
    phone: {
      title: 'Link Phone Number',
      telno: 'Phone number',
      confirm: 'Confirmation number'
    }
  },
  billing: {
    title: 'Payment Details',
    invoice: 'Invoice',
    plan: {
      title: 'Payment Plan',
      text3_1: 'Free Plan',
      text14_1: '5,000 Yen Plan',
      text: '30,000 Yen Plan',
      noPlan: 'No plan set'
    },
    method: 'Payment Method',
    noMethod: 'No payment source found'
  },
  botSettings: {
    title: 'Bot Settings',
    missing: 'Some fields are missing values.'
  },
  cancelInterview: {
    companyName: 'Company Name',
    applicantName: 'Applicant Name',
    date: 'Date',
    time: 'Time',
    operationCancelled: 'Operation Canceled',
    informApplicantTitle: `Applicants will be informed that we have reached capacity and will not be interviewing, Would you like me to send an interview cancellation notice to the applicant?`,
    informApplicantSubtitle: '*If you select "No", {serviceName} will not contact the applicant, so you must contact them directly.',
    notCanceledTitle: 'Cancel Interview',
    notCanceledSubtitle: 'Do you want to cancel the interview below?',
    alreadyCanceledTitle: 'Canceled interview',
    alreadyCanceledSubtitle: 'This interview has already been canceled.',
    applicantCanceledTitle: 'Cancellation complete',
    applicantCanceledSubtitle: 'The following interview has been canceled.',
    ownerCanceledApplicantNotNotifiedTitle: 'The interview schedule has been removed from the calendar.',
    ownerCanceledApplicantNotNotifiedSubtitle: '',
    ownerCanceledApplicantNotifiedTitle: 'We have sent a message that the interview will be canceled.',
    ownerCanceledApplicantNotifiedSubtitle: 'The interview schedule has been removed from the calendar.',
    informApplicantToCancelInterview: 'Please contact the applicant directly to cancel the interview.'
  },
  signin: {
    notRegistered: 'You can not login with this email. Try logging in with a system registered email.'
  },
  manageMail: {
    title: 'Mail Subscription Settings',
    icsTitle: 'Interview Schedule (ICS) Subscription',
    csvTitle: 'Applicant List (CSV) Subscription',
    stop: 'Stop',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly'
  },
  addToCalendar: {
    googleCalendar: 'Google Calendar',
    outlookCalendar: 'Outlook Calendar',
    yahooCalendar: 'Yahoo! Calendar',
    appleCalendar: 'iCloud Calendar',
    title: 'Interview with {name}',
    detail: 'For detail, please go here {link}',
    dateFormat: 'yyyy/MM/dd',
    timeFormat: 'HH:mm',
    sync: {
      title: 'Sync to Calendar',
      linked: '(Linked)',
      unlink: 'Unlink',
      comingSoon: '(Coming soon)'
    }
  },
  qrCodeInactive: {
    text: 'This QR code is out of use.'
  },
  applicants: {
    card: { title: 'Applicants' },
    searchField: { label: 'Search' },
    deleteDialog: {
      text: 'Are you sure you want to delete applicant ({applicantName})?',
      noApplicantNameText: 'Are you sure you want to delete applicant?'
    },
    table: {
      created: 'Created',
      name: 'Name',
      email: 'Email',
      telNo: 'Tel No',
      application: 'Bot Chat',
      action: 'Action',
      completed: 'Completed',
      notCompleted: 'Not Completed'
    },
    export: {
      buttonText: 'Download CSV'
    }
  },
  welcome: 'Welcome to {name}',
  loading: 'Loading...',
  underConstruction: 'Under Construction',
  cancel: 'Cancel',
  ok: 'OK',
  save: 'Save',
  link: 'Link',
  yes: 'Yes',
  no: 'No',
  add: 'Add',
  confirm: 'Confirm'
}

export default enMessages
