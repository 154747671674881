import { firebase } from './firebase'
import 'firebase/firestore'

// Initialize Cloud Firestore through Firebase
const firestore = firebase.firestore()
if (process.env.VUE_APP_CONNECT_EMULATOR === 'yes') {
  firestore.settings({ host: 'localhost:9090', ssl: false })
}

export { firestore }
