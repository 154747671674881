import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'
import { associateOwnerWithRedirectId } from '@/helpers/firebase-functions'
import * as Sentry from '@sentry/browser'

export async function lookupOwnerId (redirectId) {
  const doc = await firestore.collection(COLLECTIONS_ENUM.QR_REDIRECTS).doc(redirectId).get()

  if (doc.exists) {
    return {
      success: true,
      ownerId: doc.data().ownerId
    }
  } else {
    return {
      success: false
    }
  }
}

export async function associateRedirectId ({ redirectId }) {
  if (redirectId) {
    try {
      await associateOwnerWithRedirectId({ redirectId })
      return { success: true }
    } catch (error) {
      Sentry.captureException(error)
      console.error('associateOwnerWithRedirectId error', error) // eslint-disable-line no-console
      return { success: false, error }
    }
  } else {
    return false
  }
}
