import * as Sentry from '@sentry/browser'
import { firebase } from '@/helpers/firebase'
import Vue from 'vue'
import Router from 'vue-router'
import { registerCampaignInfo, decodeCampaignInfo } from '@/helpers/analytics'
import store from '@/store'
// import { isOnBoarded } from '@/helpers/firebase-functions'
// import { isOnBoarded } from '@/helpers/onboarding'
import { getUserInfo } from '@/helpers/user-info'

Vue.use(Router)

let routes = [
  {
    path: '/',
    name: 'home',
    meta: { enableSidebar: true },
    component: () =>
      import(/* webpackChunkName: "route-Recruit" */ '@/views/Recruit'),
    beforeEnter (to, from, next) {
      if (store.state.auth.isOnboardingDone) {
        next({
          name: 'second-home',
          replace: true,
          query: to.query
        })
      } else {
        next()
      }
    }
  },
  // {
  //   path: '/second-home',
  //   name: 'second-home',
  //   meta: { enableSidebar: true, isAuthProtected: true },
  //   component: () =>
  //     import(/* webpackChunkName: "route-ApplicantSearch" */ '@/views/ApplicantSearch')
  // },
  {
    path: '/second-home',
    name: 'second-home',
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Applicants" */ '@/views/Applicants')
  },
  {
    path: '/recruit',
    name: 'recruit',
    meta: { enableSidebar: false, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Recruit" */ '@/views/Recruit')
  },
  {
    path: '/jobs/:ownerId/:ownerScenarioId',
    name: 'apply',
    meta: { title: '面接予約自動受付', isAuthProtected: false },
    props: true,
    component: () =>
      import(/* webpackChunkName: "route-Apply" */ '@/views/Apply')
  },
  {
    path: '/account',
    name: 'account',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Account" */ '@/views/Account')
  },
  // {
  //   path: '/applicant-search',
  //   name: 'applicant-search',
  //   props: true,
  //   meta: { enableSidebar: true, isAuthProtected: true },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "route-ApplicantSearch" */ '@/views/ApplicantSearch'
  //     )
  // },
  {
    path: '/applicants',
    name: 'applicants',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-Applicants" */ '@/views/Applicants'
      )
  },
  {
    path: '/applicant-details/:applicantId',
    name: 'applicant-details',
    props: true,
    meta: { enableSidebar: false, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-ApplicantDetails" */ '@/views/ApplicantDetails'
      )
  },
  // {
  //   path: '/applicant-search/:objectID',
  //   name: 'applicant-search-result',
  //   props: true,
  //   meta: { enableSidebar: true, isAuthProtected: true },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "route-ApplicantSearchResult" */ '@/views/ApplicantSearchResult'
  //     )
  // },
  {
    path: '/bot-settings',
    name: 'bot-settings',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-BotSettings" */ '@/views/BotSettings')
  },
  {
    path: '/billing',
    name: 'billing',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Billing" */ '@/views/Billing')
  },
  {
    path: '/suggestion',
    name: 'suggestion',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Suggestion" */ '@/views/Suggestion')
  },
  {
    path: '/master-qr-code/:ownerId',
    name: 'master-qr-code',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-MasterQRCode" */ '@/views/MasterQRCode'
      )
  },
  {
    path: '/qr-codes/:ownerId',
    name: 'qr-codes',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-QRCodes" */ '@/views/QRCodes'
      )
  },
  {
    path: '/qr-code-stats/:ownerId',
    name: 'qr-code-stats',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-QRCodeStats" */ '@/views/QRCodeStats'
      )
  },
  {
    path: '/qr-code-inactive',
    name: 'qr-code-inactive',
    props: true,
    meta: { enableSidebar: false, isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-QRCodeInactive" */ '@/views/QRCodeInactive'
      )
  },
  {
    path: '/referral',
    name: 'referral',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Referral" */ '@/views/Referral')
  },
  {
    path: '/referral-dashboard',
    name: 'referral-dashboard',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-ReferralDashboard" */ '@/views/ReferralDashboard'
      )
  },
  {
    path: '/referrer-details/:staffId',
    name: 'referrer-details',
    props: true,
    meta: { isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-ReferrerDetails" */ '@/views/ReferrerDetails'
      )
  },
  {
    path: '/staff/:ownerId',
    name: 'staff',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-Staff" */ '@/views/Staff')
  },
  {
    path: '/staff-attendance/:ownerId',
    name: 'staff-attendance',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-StaffAttendance" */ '@/views/StaffAttendance'
      )
  },
  {
    path: '/staff-enter/:ownerId',
    name: 'staff-enter',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-StaffEnter" */ '@/views/StaffEnter'
      )
  },
  {
    path: '/staff-exit/:ownerId/',
    name: 'staff-exit',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-StaffExit" */ '@/views/StaffExit'
      )
  },
  {
    path: '/staff-sentiment/:ownerId',
    name: 'staff-sentiment',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "route-StaffSentiment" */ '@/views/StaffSentiment'
      )
  },
  {
    path: '/get-sentiment-details',
    name: 'get-sentiment-details',
    props: true,
    meta: { isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-GetSentimentDetails" */ '@/views/GetSentimentDetails'
      )
  },
  {
    path: '/main-dashboard/:ownerId',
    name: 'main-dashboard',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-MainDashboard" */ '@/views/MainDashboard'
      )
  },
  {
    path: '/order-qr-codes/:ownerId',
    name: 'order-qr-codes',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-OrderQRCodes" */ '@/views/OrderQRCodes'
      )
  },
  {
    path: '/create-qr-code/:ownerId',
    name: 'create-qr-code',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-CreateQRCode" */ '@/views/CreateQRCode'
      )
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    props: true,
    component: () =>
      import(/* webpackChunkName: "route-SignIn" */ '@/views/SignIn'),
    beforeEnter (to, from, next) {
      store.commit('auth/updateIsSigningOut', false)
      next()
    }
  },
  {
    path: '/onboard-profile',
    name: 'onboard-profile',
    props: true,
    meta: { isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-OnboardProfile" */ '@/views/OnboardProfile')
  },
  {
    path: '/payment/recruit',
    name: 'recruit-payment',
    props: true,
    meta: { isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-RecruitPayment" */ '@/views/RecruitPayment'
      )
  },
  {
    path: '/cancel-interview/:cancelToken',
    name: 'cancel-interview',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-CancelInterview" */ '@/views/CancelInterview'
      )
  },
  {
    path: '/questionaire/:questionaireToken',
    name: 'questionaire',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-Questionaire" */ '@/views/Questionaire'
      )
  },
  {
    path: '/qr-redirect/:campaignInfo',
    name: 'qr-redirect',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-QRRedirect" */ '@/views/QRRedirect'
      )
  },
  {
    path: '/update-label/:campaignId',
    name: 'update-label',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "route-UpdateLabel" */ '@/views/UpdateLabel'
      )
  },
  {
    path: '/calendar',
    name: 'calendar',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-Calendar" */ '@/views/Calendar')
  },
  {
    path: '/ownerInterviewConfirm/:applicantId',
    name: 'owner-interview-confirm',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-OwnerInterviewConfirm" */ '@/views/OwnerInterviewConfirm')
  },
  {
    path: '/manage-mail/:ownerId',
    name: 'manage-mail',
    props: true,
    meta: { enableSidebar: true },
    component: () =>
      import(
        /* webpackChunkName: "route-ManageMail" */ '@/views/ManageMail'
      )
  },
  {
    path: '/point-terminal',
    name: 'point-terminal',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-PointTerminal" */ '@/views/PointTerminal')
  },
  {
    path: '/applicant-reschedule/:rescheduleToken',
    name: 'applicant-reschedule',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-ApplicantReschedule" */ '@/views/ApplicantReschedule')
  },
  {
    path: '/applicant-confirm/:confirmToken',
    name: 'applicant-confirm',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-ApplicantConfirm" */ '@/views/ApplicantConfirm')
  },
  {
    path: '/add-to-calendar/:token',
    name: 'add-to-calendar',
    props: true,
    meta: { isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-AddToCalendar" */ '@/views/AddToCalendar')
  },
  {
    path: '/site/demo',
    name: 'site-demo',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: false },
    component: () =>
      import(/* webpackChunkName: "route-DemoSite" */ '@/views/DemoSite')
  },
  {
    path: '/landing-page-generation',
    name: 'landing-page-generation',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(/* webpackChunkName: "route-LandingPageGenerator" */ '@/views/LandingPageGenerator')
  },
  {
    path: '/fans/:ownerId',
    name: 'fans',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-Fans" */ '@/views/Fans'
      )
  },
  {
    path: '/owner-site-bot-url/:ownerId',
    name: 'owner-site-bot-url',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-OwnerSiteBotURL" */ '@/views/OwnerSiteBotURL'
      )
  },
  {
    path: '/passthrough',
    name: 'passthrough',
    props: true,
    meta: { isAuthProtected: true },
    component: () =>
      import(
        /* webpackChunkName: "route-Passthrough" */ '@/views/Passthrough'
      )
  },
  {
    path: '/under-construction',
    name: 'under-construction',
    props: true,
    meta: { enableSidebar: true, isAuthProtected: false },
    component: () =>
      import(
        /* webpackChunkName: "route-UnderConstruction" */ '@/views/UnderConstruction'
      )
  }
]

if (process.env.VUE_APP_SERVICE === 'saiyodooooon') {
  const saiyodooooonHideList = [
    'under-construction',
    'fans',
    'referral',
    'master-qr-code',
    'order-qr-codes',
    'landing-page-generation',
    'account',
    'billing'
  ]
  routes = routes.filter(route => !saiyodooooonHideList.includes(route.name))
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// We shouldn't allow user to go here unless they need to
const onboardingPaths = ['sign-in', 'onboard-profile', 'recruit-payment']

async function protectedAuthHandler ({ to, next }) {
  const { name } = to
  const {
    isLoggedIn,
    plans,
    uid,
    redirect: { to: redirectTo, used: redirectUsed }
  } = store.state.auth

  const planAlreadySet = async (uid) => {
    if (!uid) {
      return false
    }
    const userInfo = await getUserInfo(uid)
    return !!(userInfo && userInfo.plan)
  }

  const needsSignOut = !store.getters['auth/loginTypeOk']
  const needsSignIn = !isLoggedIn
  // const needsProfile = !store.getters['auth/completedProfile']
  const needsProfile = async () => {
    if (await planAlreadySet(uid)) {
      return false
    } else {
      return !store.getters['auth/completedProfile']
    }
  }

  // const needsPlan = !(isLoggedIn && plans && plans.recruitApp)
  const needsPlan = async () => {
    if (await planAlreadySet(uid)) {
      return false
    } else if (isLoggedIn && plans && ['invoice-with-site', 'invoice-without-site'].includes(plans.payment)) {
      return false
    } else {
      return !(isLoggedIn && plans && plans.recruitApp)
    }
  }
  const hasUnusedRedirect = !redirectUsed

  // console.log('protectedAuthHandler', { needsSignOut, needsSignIn, needsProfile: await needsProfile(), needsPlan: await needsPlan() })

  if (needsSignOut) {
    await store.dispatch('auth/signOut')
  }

  if (needsSignIn) {
    if (name === 'sign-in') {
      next()
    } else {
      const nextObj = {
        replace: true,
        name: 'sign-in'
      }

      if (hasUnusedRedirect) {
        nextObj.query = { signinRedirect: redirectTo }
      }
      next(nextObj)
    }
  } else if (await needsProfile()) {
    if (name === 'onboard-profile') {
      next()
    } else {
      next({ replace: true, name: 'onboard-profile' })
    }
  } else if (await needsPlan()) {
    if (name === 'onboard-profile') {
      next()
    } else if (name === 'recruit-payment') {
      next()
    } else {
      next({
        replace: true,
        name: 'recruit-payment'
      })
    }
  } else {
    /**
     * If we are here, we know they don't need to go anywhere for onboarding,
     * so if they are still trying to go to an onboarding url we will stop
     * them.
     */
    if (onboardingPaths.includes(name)) {
      next({
        replace: true,
        name: 'recruit'
      })
    } else {
      handleRedirectCheck(next)
    }
  }
}

function handleRedirectCheck (next) {
  const { to: redirectTo, used: redirectUsed } = store.state.auth.redirect
  if (redirectTo && !redirectUsed) {
    store.commit('auth/updateRedirectUsed', true)
    next({ replace: true, path: decodeURIComponent(redirectTo) })
  } else {
    next()
  }
}

/**
 * When a user tries to go to an auth-protected route, but they are not signed in,
 * this function saves their original destination so that they can continue to it
 * once they are logged in.
 *
 * @param {*} path
 * @param {*} to
 */
function setupRedirect ({ to, from }) {
  const { name } = to
  const { signinRedirect } = from.query
  const { to: redirectTo, used: redirectUsed } = store.state.auth.redirect
  switch (name) {
    case 'onboard-profile':
    case 'sign-in':
    case 'qr-redirect':
    case 'recruit-payment':
      return
    default:
      if (!redirectTo && !redirectUsed) {
        const redirectTarget =
          signinRedirect || encodeURIComponent(to.fullPath)
        store.commit('auth/updateRedirectTo', redirectTarget)
      }
  }
}

const authStateHandlerPromise = store.dispatch('auth/setupAuthStateHandler')

/**
 * If the user has campaignInfo, register with mixpanel
 */
router.beforeEach((to, from, next) => {
  const {
    query: { campaignInfo: encodedCampaignInfo, ...baseQuery },
    name,
    params
  } = to
  if (to.name === 'apply' && encodedCampaignInfo) {
    const campaignInfo = decodeCampaignInfo(encodedCampaignInfo)
    registerCampaignInfo(campaignInfo)
    // pass campaignInfo to apply
    next()
  } else if (to.name !== 'qr-redirect' && encodedCampaignInfo) {
    const campaignInfo = decodeCampaignInfo(encodedCampaignInfo)
    registerCampaignInfo(campaignInfo)
    next({
      name,
      replace: true,
      query: baseQuery,
      params
    })
  } else {
    next()
  }
})

// Wait for first authentication before continuing
router.beforeEach(async (to, from, next) => {
  await authStateHandlerPromise
  next()
})

// Save original destination for after authentication
router.beforeEach(async (to, from, next) => {
  setupRedirect({ to, from })
  next()
})

/**
 *  For routes that are auth-protected, decide which route the user
 *  should be at and send them there
 */
// const authProtectedRoutes = [
//   'home',
//   'recruit',
//   'onboard-profile',
//   'sign-in',
//   'recruit-payment',
//   'account',
//   'bot-settings',
//   'update-label',
//   'billing',
//   'applicant-search',
//   'staff-sentiment',
//   'applicant-search-result',
//   'manage-mail',
//   'landing-page-generation',
//   'second-home'
// ]

router.beforeEach(async (to, from, next) => {
  const { meta } = to
  const isAuthRoute = meta && meta.isAuthProtected !== false
  if (isAuthRoute) {
    protectedAuthHandler({ next, to })
  } else {
    handleRedirectCheck(next)
  }
})

// // Workaround to goto the second home instead of the bot chat
// router.beforeEach(async (to, from, next) => {
//   if (to.name === 'home') {
//     if (!store.state.auth.isOnboardingDone) {
//       const { uid } = store.state.auth
//       const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
//       // const { data: { result: isOnBoarded_ } } = await isOnBoarded({ ownerId: uid, scenarioId: ownerScenarioId })
//       const isOnBoarded_ = await isOnBoarded({ ownerId: uid, scenarioId: ownerScenarioId })
//       store.state.auth.isOnboardingDone = isOnBoarded_
//     }
//     if (store.state.auth.isOnboardingDone) {
//       next({
//         name: 'second-home',
//         replace: true
//       })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

/**
 * For routes that need anonymous authentication
 */
const anonymousLoginRoutes = ['apply']
router.afterEach(async (to, from) => {
  const { name } = to
  const { isLoggedIn, isAuthenticating } = store.state.auth

  const needsLogin = !isLoggedIn && !isAuthenticating
  const isAnonRoute = anonymousLoginRoutes.includes(name)

  if (isAnonRoute && needsLogin) {
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#setpersistence
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#persistence-1
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    return firebase
      .auth()
      .signInAnonymously()
      .catch(function (error) {
        // Handle Errors here.
        Sentry.captureException(error)
        // eslint-disable-next-line
        console.error("Login anonymously error: ", error);
        // ...
      })
  }
})

router.onError(error => {
  if (/loading chunk .* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
