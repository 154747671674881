<template>
  <v-navigation-drawer
    v-if="showSidebar"
    clipped
    width="300"
    v-model="drawer"
    app
  >
    <v-list dense>
      <!-- <v-list-item link :to="`/main-dashboard/${this.uid}`">
          <v-list-item-action>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ホーム</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

      <v-list-item link to="/applicants">
        <v-list-item-action>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("m.drawer.home") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link to="/under-construction">
          <v-list-item-action>
            <v-icon>mdi-inbox</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>受信箱</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

      <!-- <v-list-item link to="/suggestion">
          <v-list-item-action>
            <v-icon>mdi-lightbulb-on</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>サジェスチョン</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

      <v-list-item link to="/calendar">
        <v-list-item-action>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("m.drawer.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group prepend-icon="mdi-account-multiple" no-action>
        <template v-slot:activator>
          <v-list-item-title>{{ $t("m.drawer.team") }}</v-list-item-title>
        </template>
        <v-list-item link to="/applicants">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("m.drawer.applicant")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/under-construction" v-if="show">
          <v-list-item-content>
            <v-list-item-title>{{ $t("m.drawer.staff") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="`/fans/${this.uid}`" v-if="!hideFans && show">
          <v-list-item-content>
            <v-list-item-title>{{ $t("m.drawer.fan") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="mdi-qrcode" no-action>
        <template v-slot:activator>
          <v-list-item-title>{{ $t("m.drawer.flow") }}</v-list-item-title>
        </template>

        <v-list-item link :to="`/qr-code-stats/${this.uid}`">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("m.drawer.statistics")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="`/qr-codes/${this.uid}`">
          <v-list-item-content>
            <v-list-item-title>{{
              $t("m.drawer.mediaQRCode")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-group sub-group value="true" no-action>
            <template v-slot:activator>
              <v-list-item-title>採用媒体掲載</v-list-item-title>
            </template>
            <v-list-item link :to="`/media-a-qr-code/${this.uid}`">
              <v-list-item-content>
                <v-list-item-title>媒体A掲載用</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="`/media-b-qr-code/${this.uid}`">
              <v-list-item-content>
                <v-list-item-title>媒体B掲載用</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="`/media-c-qr-code/${this.uid}`">
              <v-list-item-content>
                <v-list-item-title>媒体C掲載用</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>-->

        <v-list-item link to="/referral" v-if="show">
          <v-list-item-content>
            <v-list-item-title>{{ $t("m.drawer.friends") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="`/create-qr-code/${this.uid}`">
          <v-list-item-content>
            <v-list-item-title>{{ $t("m.drawer.createQRCode") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-group sub-group value="true" no-action> -->
        <!-- <template v-slot:activator>
              <v-list-item-title>{{$t('m.drawer.staffRoom')}}</v-list-item-title>
          </template>-->
        <!-- <v-list-item link :to="`/staff-attendance/${this.uid}`">
              <v-list-item-content>
                <v-list-item-title>スタッフ入店・退店用QRコード</v-list-item-title>
              </v-list-item-content>
          </v-list-item>-->
        <!-- </v-list-group> -->

        <v-list-group sub-group value="true" no-action v-if="show">
          <template v-slot:activator>
            <v-list-item-title>{{ $t("m.drawer.inside") }}</v-list-item-title>
          </template>
          <v-list-item link :to="`/master-qr-code/${this.uid}`" v-if="show">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.drawer.masterQRCode")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="`/order-qr-codes/${this.uid}`" v-if="show">
            <v-list-item-content>
              <v-list-item-title>{{ $t("m.drawer.poster") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          value="true"
          no-action
          v-if="!isInvoiceWithoutSite && !hideJobs && show"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t("m.drawer.search") }}</v-list-item-title>
          </template>
          <!-- <v-list-item link to="/under-construction">
              <v-list-item-content>
                <v-list-item-title>URL</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
          <v-list-item link to="/landing-page-generation" v-if="show">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.drawer.pageSettings")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link to="/site-page-update">
              <v-list-item-content>
                <v-list-item-title>採用サイト・募集職種の編集</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
        </v-list-group>

        <v-list-group sub-group value="true" no-action>
          <template v-slot:activator>
            <v-list-item-title>{{
              $t("m.drawer.ownerSite")
            }}</v-list-item-title>
          </template>
          <!-- <v-list-item link to="/under-construction">
              <v-list-item-content>
                <v-list-item-title>JS埋め込みコード</v-list-item-title>
              </v-list-item-content>
            </v-list-item>-->
          <v-list-item link :to="`/owner-site-bot-url/${this.uid}`">
            <v-list-item-content>
              <v-list-item-title>{{
                $t("m.drawer.qrCodeURL")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-group>

      <!-- <v-list-item link to="/recruit">
          <v-list-item-action>
            <v-icon>mdi-robot</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ボット</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

      <v-divider></v-divider>

      <v-list-group prepend-icon="mdi-tune" no-action>
        <template v-slot:activator>
          <v-list-item-title>{{ $t("m.drawer.settings") }}</v-list-item-title>
        </template>
        <v-list-item link to="/account" v-if="show">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("m.drawer.accountSettings")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/billing" v-if="show">
          <v-list-item-action>
            <v-icon>mdi-currency-jpy</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("m.drawer.payment") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/bot-settings">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("m.drawer.botSettings")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item link :to="`/manage-mail/${this.uid}`">
            <v-list-item-action>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{$t('m.manageMail.title')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
      </v-list-group>

      <v-list-item @click="onSignOut">
        <v-list-item-action>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("m.drawer.signOut") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    enableSidebar: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    show: process.env.VUE_APP_SERVICE !== 'saiyodooooon'
  }),
  computed: {
    ...mapState('auth', ['isLoggedIn', 'uid', 'isAnonymous', 'plans', 'isOnboardingDone']),
    drawer: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:drawer', value)
      }
    },
    showSidebar () {
      return this.enableSidebar && this.isOnboardingDone
    },
    isInvoiceWithoutSite () {
      return this.plans.payment === 'invoice-without-site'
    },
    hideFans () {
      if (!this.plans.recruitApp) { // epark
        return false
      }
      const { plan } = this.plans.recruitApp
      return plan === process.env.VUE_APP_STRIPE_PLAN_FREE
    },
    hideJobs () {
      if (!this.plans.recruitApp) { // epark
        return false
      }
      const { plan } = this.plans.recruitApp
      return plan === process.env.VUE_APP_STRIPE_PLAN_FREE || plan === process.env.VUE_APP_STRIPE_PLAN_5K
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    async onSignOut () {
      await this.signOut()
      this.$router.replace({ name: 'sign-in', params: { redirect: encodeURIComponent('/') } })
    }
  },
  watch: {
    enableSidebar (enableSidebar, oldEnableSidebar) {
      if (enableSidebar && oldEnableSidebar === false) {
        this.drawer = true
      }
    }
  }
}
</script>

<style>
</style>
