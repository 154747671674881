import Vue from 'vue'
// import Vuetify from 'vuetify'
import Vuetify, { colors } from 'vuetify/lib'
import { Touch, Ripple } from 'vuetify/lib/directives'
import en from 'vuetify/es5/locale/en'
import ja from 'vuetify/es5/locale/ja'

const browserLocale = navigator.language.split('-')[0]

Vue.use(Vuetify, {
  directives: {
    Touch,
    Ripple
  }
})

export default new Vuetify({
  lang: {
    locales: { en, ja },
    current: browserLocale || 'ja'
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        primary: colors.cyan.darken2
      },
      light: {
        primary: colors.amber.darken1
      }
    },
    dark: true
  }
})
