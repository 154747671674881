<template>
  <Visual :snackbar.sync="snackbar" :success="success" :error="error" />
</template>

<script>
import { mapState } from 'vuex'
import { associateRedirectId } from '@/helpers/qr-redirects'
import Visual from './visual'
export default {
  components: {
    Visual
  },
  data () {
    return {
      snackbar: false,
      success: true,
      error: null
    }
  },
  computed: {
    ...mapState('auth', ['isLoggedIn', 'isAnonymous']),
    shouldAssociate () {
      return this.isLoggedIn && !this.isAnonymous && this.$route.query.redirectId
    }
  },
  watch: {
    shouldAssociate: {
      immediate: true,
      async handler (shouldAssociate) {
        if (shouldAssociate) {
          const { redirectId, ...baseQuery } = this.$route.query
          const { success, error } = await associateRedirectId({ redirectId: this.$route.query.redirectId })
          this.success = success
          this.error = error ? error.message : null
          this.snackbar = true
          this.$router.replace({ query: baseQuery })
        }
      }
    }
  }
}
</script>

<style>
</style>
