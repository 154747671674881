import { firebase } from '@/helpers/firebase'
import { firestore } from '@/helpers/firestore'
import { updateUserImage, updateUserPaymentSource, updateUserProfile } from '@/helpers/firebase-functions'
import { COLLECTIONS_ENUM, ACCOUNT_LOGIN_TYPES } from '@/enums'
import { getUserProfile } from '@/helpers/user-profile'
import { identifyUser, identifyUserPlans } from '@/helpers/analytics'
import * as Sentry from '@sentry/browser'
import jwtDecode from 'jwt-decode'
import { cacheManager } from '@/helpers/cache'
import { schedulerlyClient } from '@/helpers/schedulerly'
import { isOnBoarded } from '@/helpers/onboarding'
const USER_IMAGE_KEY = 'userimages'

export const state = () => ({
  userDisplayName: null,
  userAuthEmail: null,
  isAuthenticating: true,
  isLoggedIn: false,
  isSigningOut: false,
  uid: null,
  isOnboardingDone: false,
  subscriptionEnder: null,
  isAnonymous: null,
  plans: {
    payment: 'card',
    recruitApp: null
  },
  plansLoaded: false,
  idToken: null,
  providerData: [],
  userImage: null,
  authTime: null,
  phoneConfirmationResult: null,
  snsLinkLoginErrorCode: null,
  rawProfile: {
    firstName: null,
    givenName: null,
    email: null
  },
  redirect: {
    to: null,
    used: false
  }
})

export const mutations = {
  updateUid (state, value) {
    state.uid = value
  },
  updateUserDisplayName (state, value) {
    state.userDisplayName = value
  },
  updateIsAuthenticating (state, value) {
    state.isAuthenticating = value
  },
  updateIsLoggedIn (state, value) {
    state.isLoggedIn = value
  },
  updateIsSigningOut (state, value) {
    state.isSigningOut = value
  },
  updateIdToken (state, value) {
    const decoded = jwtDecode(value)
    state.idToken = value
    state.authTime = decoded.auth_time
  },
  updateSnsLinkLoginErrorCode (state, value) {
    state.snsLinkLoginErrorCode = value
  },
  updateProviderData (state, user) {
    state.providerData = user ? user.providerData : []
  },
  updatePhoneConfirmationResult (state, value) {
    state.phoneConfirmationResult = value
  },
  updateUserImage (state, value) {
    state.userImage = value
  },
  updateIsOnboardingDone (state, value) {
    state.isOnboardingDone = value
  },
  updateTempEmailCredentials (state, value) {
    state.tempEmailCredentials = value
  },
  updateAuthStatus (state, { user }) {
    const isLoggedIn = !!user
    const hasAuthEmail = !!user && !!user.email
    state.userDisplayName = isLoggedIn ? user.displayName : null
    state.uid = isLoggedIn ? user.uid : null
    state.userAuthEmail = hasAuthEmail ? user.email : null
    state.isAuthenticating = false
    state.isLoggedIn = isLoggedIn
    // state.isSigningOut = false
    state.isAnonymous = user ? user.isAnonymous : null
  },
  resetState (state) {
    state.isLoggedIn = false
    state.isAuthenticating = true
    state.userAuthEmail = null
    // state.isSigningOut = false
    state.userDisplayName = null
    state.isAnonymous = null
    state.claims = null
  },
  updateSubscriptionEnder (state, value) {
    state.subscriptionEnder = value
  },
  updateRedirectTo (state, value) {
    state.redirect.to = value
  },
  updateRedirectUsed (state, value) {
    state.redirect.used = value
  },
  updateUserPlans (state, value) {
    state.plans = { ...state.plans, ...value }
    state.plansLoaded = true
  },
  updateProfile (state, profile) {
    const { firstName, givenName, email } = profile || {}
    state.rawProfile = Object.assign(state.rawProfile, { firstName, givenName, email })
  }
}

function calculateAuthProvider (providerId, credentials) {
  switch (providerId) {
    case ACCOUNT_LOGIN_TYPES.phone:
      return new firebase.auth.PhoneAuthProvider()
    case ACCOUNT_LOGIN_TYPES.facebook:
      return new firebase.auth.FacebookAuthProvider()
    case ACCOUNT_LOGIN_TYPES.google:
      return new firebase.auth.GoogleAuthProvider()
    case ACCOUNT_LOGIN_TYPES.email:
      return firebase.auth.EmailAuthProvider.credential(credentials.email, credentials.password)
  }
}

export const actions = {
  async refreshUserDocument ({ state, commit }) {
    if (state.uid) {
      const [userDoc, isOnboardingDone] = await Promise.all([
        firestore
          .collection(COLLECTIONS_ENUM.USERS)
          .doc(state.uid)
          .get()
          .then(doc => (doc.exists ? doc.data() : {})),
        isOnBoarded({ ownerId: state.uid })
      ])

      commit('updateUserPlans', userDoc.plans)
      commit('updateProfile', userDoc.profile)
      commit('updateUserImage', userDoc.userImage)
      commit('updateIsOnboardingDone', isOnboardingDone)
      identifyUserPlans(userDoc.plans)
    }
  },
  async updateUserImage ({ state, commit }, file) {
    if (state.uid) {
      const storageRef = firebase.storage().ref().child(`${USER_IMAGE_KEY}/${state.uid}`)
      const uploadTask = storageRef.put(file)
      return new Promise((resolve, reject) => {
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED,
          snapshot => { },
          error => {
            reject(error)
          },
          async () => {
            const userImageUrl = await uploadTask.snapshot.ref.getDownloadURL()
            commit('updateUserImage', userImageUrl)
            resolve(userImageUrl)
          }
        )
      })
        .then(userImageUrl => {
          return updateUserImage({ userImageUrl })
        })
    } else {
      throw new Error('Cannot upload image if missing uid')
    }
  },
  async updateUserPaymentSource ({ dispatch }) {
    await updateUserPaymentSource()
    return dispatch('refreshUserDocument')
  },
  async linkProvider (context, providerId) {
    const authProvider = calculateAuthProvider(providerId)
    firebase.auth().currentUser.linkWithRedirect(authProvider)
  },
  async linkEmail ({ commit, state }, credentials) {
    commit('updateTempEmailCredentials', credentials)
    const authProvider = calculateAuthProvider(ACCOUNT_LOGIN_TYPES.email, credentials)
    // try {
    const { user } = await firebase.auth().currentUser.linkAndRetrieveDataWithCredential(authProvider)
    commit('updateAuthStatus', { user })
    commit('updateProviderData', user)
  },
  async beginLinkPhone ({ commit, state }, { phoneNumber, element }) {
    const appVerifier = new firebase.auth.RecaptchaVerifier(
      element, { size: 'invisible' })
    const confirmationResult = await firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, appVerifier)
    commit('updatePhoneConfirmationResult', confirmationResult)
  },
  async confirmLinkPhone ({ commit, state }, code) {
    const { user } = await state.phoneConfirmationResult.confirm(code)
    commit('updateAuthStatus', { user })
    commit('updateProviderData', user)
  },
  async unlinkProvider ({ commit }, providerId) {
    await firebase.auth().currentUser.unlink(providerId)
    await firebase.auth().currentUser.reload()
    const user = firebase.auth().currentUser
    commit('updateAuthStatus', { user })
    commit('updateProviderData', user)
  },
  async getIdToken ({ commit }, user) {
    if (user) {
      const idToken = await user.getIdToken()
      commit('updateIdToken', idToken)
    }
  },
  async login ({ commit, dispatch, state }, user) {
    commit('updateAuthStatus', { user })
    commit('updateProviderData', user)
    await dispatch('getIdToken', user)
    await dispatch('refreshUserDocument')
    await identifyUser(user)
  },
  setupAuthStateHandler ({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      // const shouldSetupAuthstate =
      //   !state.isLoggedIn && state.isAuthenticating && !state.isSigningOut
      // if (shouldSetupAuthstate) {
      const ender = firebase.auth().onAuthStateChanged(user => {
        resolve(dispatch('login', user))
      })
      commit('updateSubscriptionEnder', ender)

      firebase.auth().getRedirectResult().then(result => {
        if (result.credential) {
          dispatch('login', result.user)
        }
      }).catch(err => {
        Sentry.captureException(err)
        console.error('getRedirectResult error', err) // eslint-disable-line no-console
        commit('updateSnsLinkLoginErrorCode', err.code)
      })
    })
    // }
  },
  async endSubscription ({ state, commit }) {
    if (state.subscriptionEnder) {
      await state.subscriptionEnder()
      commit('updateSubscriptionEnder', null)
    }
  },
  async signOut ({ commit, dispatch }) {
    commit('updateIsSigningOut', true)
    try {
      await dispatch('endSubscription')
      await firebase.auth().signOut()
      await schedulerlyClient.signOut()
      commit('resetState')
      commit('applicantSearch/reset', null, { root: true })
      dispatch('setupAuthStateHandler')

      // Clear user cache
      cacheManager.clear()
    } catch (err) {
      Sentry.captureException(err)
      // eslint-disable-next-line
      console.error('error signing out of firebase', err)
    }
  },
  async updateProfile ({ commit }, profile) {
    const { uid, firstName, givenName, email } = profile
    await updateUserProfile({ ownerId: uid, profile: { firstName, givenName, email } })
    commit('updateProfile', profile)
  },
  async getProfile ({ commit }, uid) {
    const profile = await getUserProfile(uid)
    if (profile) {
      commit('updateProfile', profile)
    }
  }
}

export const getters = {
  completedProfile (state) {
    return !!state.rawProfile && !!state.rawProfile.firstName && !!state.rawProfile.givenName && !!state.rawProfile.email
  },
  profile (state) {
    return state.rawProfile || {}
  },
  loginTypeOk (state) {
    return process.env.VUE_APP_SKIP_LOGIN === 'yes' || state.isAnonymous === false
  }
}
