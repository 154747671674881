const jaMessages = {
  drawer: {
    home: 'ホーム',
    calendar: 'カレンダー',
    team: '人',
    applicant: '応募者',
    staff: 'スタッフ',
    fan: 'ファン',
    flow: '導線',
    statistics: 'QRコード募集者集計',
    mediaQRCode: 'メディア掲載用QRコード',
    staffRoom: 'スタッフルーム',
    friends: 'お友達紹介プログラム',
    createQRCode: 'QRコードの追加',
    inside: '店内',
    masterQRCode: 'スタッフ募集用QRコード',
    poster: 'ポスター・QRコードステッカー',
    search: '検索エンジン',
    pageSettings: 'ページ設定',
    ownerSite: '自社ウェブサイト掲載',
    qrCodeURL: '短縮URL',
    settings: '設定',
    accountSettings: 'アカウント設定',
    payment: 'お支払い情報',
    botSettings: 'ボット設定',
    signOut: 'サインアウト'
  },
  profile: {
    title: 'プロフィール',
    givenName: '氏名（姓）',
    firstName: '氏名（名）',
    email: 'メールアドレス',
    next: '次へ',
    back: '戻る（サインアウト）'
  },
  calendar: {
    all: '全て',
    scheduled: '予定あり',
    available: '予定なし',
    createMultipleSlot: '複数予約枠をまとめて作成',
    createSingleSlot: '予約枠を作成',
    attendee: {
      add: '応募者の追加',
      max: '応募者（上限人数：{max}）',
      actions: '操作'
    },
    title: {
      addSlot: '同じ時間帯に別の面接枠がありますが、このまま面接枠を追加してもよろしいですか？',
      maxAttendee: '上限人数分の面接予約がすでに入っているため人数を減らす事はできません',
      startTime: '予約が入っている面接枠を変更することはできません',
      deleteAttendee: '面接をお断りしますか？',
      showDeletePrev: '変更前の日時はご都合がよろしくないようですね。面接枠を削除しておきますか？',
      export: 'カレンダーをエクスポート'
    },
    table: {
      name: '氏名',
      action: '操作'
    },
    reschedule: {
      title: '「面接日時」を変更してください。応募者様へ日時変更依頼のメッセージを送信いたします。'
    }
  },
  applicant: {
    title: '応募者',
    loading: '読込み中',
    noData: '該当者なし',
    counts: '件数',
    notFilled: '未記入',
    download: '応募者リストのダウンロード（CSV）',
    details: {
      title: '応募者情報'
    }
  },
  fans: {
    title: 'ファンリスト',
    send: '「応募再開のお知らせ」の送付',
    dialog: {
      title: 'お知らせの送付',
      text: '応募受付が停止中です。応募受付を再開し、お知らせを送付します。'
    },
    table: {
      created: '登録日時',
      name: '氏名',
      contact: 'メールアドレス/電話番号'
    }
  },
  stats: {
    title: 'QRコード募集者集計',
    dialog: {
      title: 'QRコード・ラベルの編集',
      label: 'ラベル'
    },
    table: {
      label: 'ラベル',
      id: 'ID',
      applicants: '募集者数',
      scans: 'スキャン回数',
      deletedDateTime: '削除日時',
      action: '操作'
    }
  },
  media: {
    title: '媒体掲載用QRコード',
    create: 'QRコードの作成',
    createDialog: {
      title: 'QRコードの作成'
    },
    updateDialog: {
      title: 'QRコードの編集'
    },
    table: {
      label: 'ラベル',
      id: 'ID',
      url: 'リンク',
      action: '操作'
    },
    deleteDialog: {
      text: 'QRコード({label})を削除します。よろしいですか?'
    }
  },
  qrCode: {
    subtitle: 'QRコードのラベルを入力してください。',
    label: 'QRコードのラベル',
    creating: 'QRコードの作成中...'
  },
  referral: {
    title: 'お友達紹介プログラム',
    subtitle: `従業員の皆さまに紹介をお願いする際にこのQRコードやURLをご活用ください。<br/>
    紹介から応募があった場合に、どの従業員様から紹介された応募者かがわかるようになります。<br/><br/>
    【活用例】<br/>
    QRコード付きのポスターを従業員控室に掲示、シフトの連絡に使用しているグループにURLを送る、などして従業員の皆様へお友達紹介をお願いする<br/><br/>
    【応募までの流れ】</br>
    従業員AさんがこのQRコード・URLにアクセスする → 従業員Aさん専用のQRコード・URLが作成される → 面接を希望しているお友達に自分のQRコード・URLをお知らせ → お友達が応募`,
    creating: 'QRコードの作成中...',
    qrCodeCard: {
      title: 'お友達紹介プログラム用QRコード'
    },
    label: 'お友達紹介プログラム'
  },
  master: {
    title: 'スタッフ募集用QRコード',
    subtitle: 'こちらのQRコードから面接の日時設定ができます。応募者の方にご提示ください。',
    creating: 'QRコードの作成中...',
    qrCodeCard: {
      title: 'スタッフ募集用QRコード'
    }
  },
  order: {
    title: 'ポスター/ステッカー用QRコード',
    text: '店内用の「スタッフ募集ポスター」および「ステッカー形式のスタッフ募集用QRコード」をご依頼いただけます。（期間限定の無料サービス）<br/>※ご依頼される内容・数量によっては有料となる場合がございます。まずはお気軽にご相談ください。担当者よりご連絡いたします。',
    ordered: 'ポスターおよびステッカーの作成を依頼しました。',
    doOrder: '作成を依頼する'
  },
  landing: {
    title: '採用サイトの作成',
    saved: '保存されました',
    updateJob_1: '採用サイトの作成を行う前に',
    updateJob_2: 'で職種の更新をしてください。',
    store: {
      title: '企業(店舗)のページ',
      publicURL: '企業(店舗)の公開サイトのURL',
      previewURL: 'プレビューのURL'
    },
    jobs: {
      title: '募集職種のページ',
      publicURL: 'の公開サイトのURL',
      previewURL: 'のプレビューのURL',
      pageOf: 'のページ'
    },
    addJob: '職種の追加',
    notFilled: '未入力の項目が残っています。',
    save: '保存とプレビュー',
    upload: 'サイトを公開/更新する',
    newJob: '新しい職種'
  },
  url: {
    title: '自社サイト掲載用URL',
    subtitle: 'こちらのURLを自社ウェブサイト掲載用としてお使いください。',
    label: '自社サイト掲載'
  },
  account: {
    title: 'アカウント設定',
    loginAgain: 'アカウント連携を編集するには再度ログインが必要です。',
    reauth: '再認証（ログイン）',
    accountLink: 'アカウント連携',
    link: '連携',
    unlink: '解除',
    profile: {
      title: 'プロフィール情報',
      givenName: '氏名（姓）',
      firstName: '氏名（名）',
      email: 'メールアドレス',
      save: '更新'
    },
    email: {
      title: 'メールアカウントの連携',
      email: 'メールアドレス',
      password: 'パスワード',
      password2: 'パスワードの確認'
    },
    phone: {
      title: '電話番号の連携',
      telno: '電話番号',
      confirm: '確認番号'
    }
  },
  billing: {
    title: 'お支払い情報',
    invoice: '請求書払い',
    plan: {
      title: 'プラン',
      text3_1: '無料プラン',
      text14_1: '5,000円プラン',
      text: '30,000円プラン',
      noPlan: 'プランが設定されていません'
    },
    method: '支払方法',
    noMethod: '支払い方法が設定されていません。'
  },
  botSettings: {
    title: 'ボット設定',
    missing: '未入力の項目が残っています。'
  },
  cancelInterview: {
    companyName: '会社名',
    applicantName: '応募者',
    date: '日付',
    time: '開始時間',
    operationCanceled: '操作を取り消しました',
    informApplicantTitle: '応募者様には、今回募集したお仕事が定員に達したために面接を中止とさせていただく旨をお知らせいたします。面接中止のお知らせを送信してよろしいですか？',
    informApplicantSubtitle: '※「いいえ」を選択された場合は、応募者様へ{serviceName}から自動的に連絡はいたしませんので、直接ご連絡していただく必要がございます。',
    notCanceledTitle: '面接のキャンセル',
    notCanceledSubtitle: '下記の面接をキャンセルしますか？',
    alreadyCanceledTitle: 'キャンセル済みの面接',
    alreadyCanceledSubtitle: 'この面接はすでにキャンセルされています。',
    applicantCanceledTitle: 'キャンセル手続きの完了',
    applicantCanceledSubtitle: '下記の面接はキャンセルされました。',
    ownerCanceledApplicantNotNotifiedTitle: 'カレンダーから面接スケジュールは削除されました。',
    ownerCanceledApplicantNotNotifiedSubtitle: '',
    ownerCanceledApplicantNotifiedTitle: '面接中止の旨、メッセージを送信いたしました。',
    ownerCanceledApplicantNotifiedSubtitle: 'カレンダーから面接スケジュールは削除されました。',
    informApplicantToCancelInterview: '応募者様へお断りのご連絡をお願いいたします。'
  },
  signin: {
    notRegistered: 'このメールアドレスではログインできません。システムに登録したメールアドレスを使ってログインしてください。'
  },
  manageMail: {
    title: 'メールの配信設定',
    icsTitle: '面接スケジュール（ICSファイル）の配信',
    csvTitle: '募集者リスト（CSVファイル）の配信',
    stop: '配信停止',
    daily: '毎日配信',
    weekly: '毎週配信',
    monthly: '毎月配信'
  },
  addToCalendar: {
    googleCalendar: 'Google カレンダー',
    outlookCalendar: 'Outlook カレンダー',
    yahooCalendar: 'Yahoo! カレンダー',
    appleCalendar: 'iCloud カレンダー',
    title: '{name} と面接',
    detail: '詳細はこちらから {link}',
    dateFormat: 'yyyy年MMMdd日',
    timeFormat: 'HH:mm'
  },
  qrCodeInactive: {
    text: 'このQRコードは無効化されています。<br/>現在はご利用できません。'
  },
  applicants: {
    card: { title: '募集者リスト' },
    searchField: { label: '検索' },
    deleteDialog: {
      text: '募集者（{applicantName}）を削除します。よろしいですか？',
      noApplicantNameText: '募集者を削除します。よろしいですか？'
    },
    table: {
      created: '作成日時',
      name: '氏名',
      email: 'メールアドレス',
      telNo: '電話番号',
      application: 'チャットボット',
      action: '操作',
      completed: '完了',
      notCompleted: '未完'
    },
    export: {
      buttonText: 'CSVダウンロード'
    }
  },
  welcome: '{name}へようこそ',
  loading: '読み込み中',
  underConstruction: '現在この機能は準備中です',
  cancel: 'キャンセル',
  ok: 'OK',
  save: '保存',
  link: '連携',
  yes: 'はい',
  no: 'いいえ',
  add: '追加',
  confirm: '確認'
}

export default jaMessages
