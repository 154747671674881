import mixpanel from 'mixpanel-browser'
import { firestore } from '@/helpers/firestore'
import { realtimeCampaignNotification } from '@/helpers/firebase-functions'
import * as Sentry from '@sentry/browser'
import once from 'lodash.once'

export const EVENT_NAMES = {
  LOADED_CC_FORM: 'Loaded Credit Card Form',
  COMPLETED_PAYMENT: 'Completed Payment',
  LOADED_SCENARIO: 'Loaded Scenario',
  ONBOARDED_FROM_CAMPAIGN: 'Onboarded from campaign',
  COMPLETED_SCENARIO: 'Completed Scenario',
  LOADED_APP: 'Loaded Application',
  SCHEDULED_INTERVIEW: 'Scheduled Interview',
  SCENARIO_LABEL_SEEN: 'Scenario Label Seen',
  USER_INPUT_DATA: 'User input data',
  LOADED_LANDING_PAGE_GENERATOR: 'Loaded Landing Page Generator',
  LOADED_ACCOUNT: 'Loaded Account',
  LOADED_ADD_TO_CALENDAR: 'Loaded Add to Calendar',
  LOADED_APPLICANT_CONFIRM: 'Loaded Applicant Confirmation',
  LOADED_APPLICANT_RESCHEDULE: 'Loaded Applicant Rescheduled',
  LOADED_APPLICANT_SEARCH: 'Loaded Applicant Search',
  LOADED_APPLICANT_SEARCH_RESULT: 'Loaded Applicant Search Result',
  LOADED_APPLY: 'Loaded Apply',
  LOADED_BILLING: 'Loaded Billing',
  LOADED_BOT_SETTINGS: 'Loaded Bot Settings',
  LOADED_CALENDAR: 'Loaded Calendar',
  LOADED_CANCEL_INTERVIEW: 'Loaded Cancel Interview',
  LOADED_DEMO_SITE: 'Loaded Demo Site',
  LOADED_FANS: 'Loaded Fans',
  LOADED_GET_SENTIMENT_DETAILS: 'Loaded Sentiment Details',
  LOADED_MAIN_DASHBOARD: 'Loaded Main Dashboard',
  LOADED_MANAGE_MAIL: 'Loaded Manage Mail',
  LOADED_MASTER_QR_CODE: 'Loaded Master QR Code',
  LOADED_ONBOARD_PROFILE: 'Loaded Onboard Profile',
  LOADED_ORDER_QR_CODES: 'Loaded Order QR Codes',
  LOADED_OWNER_INTERVIEW_CONFIRM: 'Loaded Owner Interview Confirmation',
  LOADED_OWNER_SITE_BOT_URL: 'Loaded Owner Site Bot URL',
  LOADED_QR_CODES: 'Loaded QR Codes',
  LOADED_QR_CODE_STATS: 'Loaded QR Code Statics',
  LOADED_QR_REDIRECT: 'Loaded QR Redirect',
  LOADED_QUESTIONAIRE: 'Loaded Questionaire',
  LOADED_RECRUIT: 'Loaded Recruit',
  LOADED_RECRUIT_PAYMENT: 'Loaded Recruit Payment',
  LOADED_REFFERAL: 'Loaded Referral',
  LOADED_REFERRAL_DASHBOARD: 'Loaded Referral Dashboard',
  LOADED_REFERRER_DETAILS: 'Loaded Referrer Details',
  LOADED_SIGN_IN: 'Loaded Sign In',
  LOADED_STAFF: 'Loaded Staff',
  LOADED_STAFF_ATTENDANCE: 'Loaded Staff Attendance',
  LOADED_STAFF_ENTER: 'Loaded Staff Enter',
  LOADED_STAFF_EXIT: 'Loaded Staff Exit',
  LOADED_STAFF_SENTIMENT: 'Loaded Staff Sentiment',
  LOADED_SUGGESTION: 'Loaded Suggestion',
  LOADED_UNDER_CONSTRUCTION: 'Loaded Under Construction',
  LOADED_UPDATE_LABEL: 'Loaded Update Label'
}

export function recreateMixpanel () {
  Sentry.captureMessage(`recreating mixpanel because window.mixpanel is: ${JSON.stringify(window.mixpanel, null, 2)}`)
  mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY, { debug: process.env.VUE_APP_MIXPANEL_DEBUGGING === 'yes' }, 'recreated')
  return mixpanel.recreated
}

export function analyticsEvent (eventName, metadata) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel

  if (metadata) {
    const result = mixpanel.track(eventName, metadata)
    if (result === false) {
      Sentry.captureMessage(`Could not mixpanel.track: ${eventName} ${JSON.stringify(metadata, null, 2)}`)
    }
  } else {
    const result = mixpanel.track(eventName)
    if (result === false) {
      Sentry.captureMessage(`Could not mixpanel.track: ${eventName}`)
    }
  }
}

export function decodeCampaignInfo (encodedCampaignInfo) {
  // return JSON.parse(atob(decodeURIComponent(encodedCampaignInfo)))
  return JSON.parse(decodeURIComponent(atob(encodedCampaignInfo)))
}

let theOwnerId = null
const onceRegisterCampaignInfo = once(function (campaignInfo) {
  const { campaignId, campaignType, ownerScenarioId, ownerId } = campaignInfo

  const analyticsCampaignInfo = {
    campaignId,
    campaignType,
    ownerScenarioId,
    ownerId
  }
  theOwnerId = ownerId // remember ownerId for sentry

  // mixpanel.register_once(analyticsCampaignInfo)
  // mixpanel.people.set(analyticsCampaignInfo)

  analyticsEvent(EVENT_NAMES.ONBOARDED_FROM_CAMPAIGN, analyticsCampaignInfo)
  realtimeCampaignNotification(analyticsCampaignInfo)
})

export function registerCampaignInfo (campaignInfo) {
  return onceRegisterCampaignInfo(campaignInfo)
}

export async function identifyUser (user) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel

  if (user) {
    aliasUserIfNewSignup(user)
    const hasAuthEmail = !!user && !!user.email
    mixpanel.identify(user.uid)

    const userProps = {
      uid: user.uid
    }

    if (user.displayName) {
      userProps.displayName = user.displayName
    }

    if (hasAuthEmail) {
      userProps.userAuthEmail = user.email
    }

    if (user.isAnonymous) {
      userProps.isAnonymous = user.isAnonymous
    }

    let owner = await firestore.collection('OWNERS').doc(user.uid).collection('SCENARIO_PUBLIC').doc('I50eomEXZFI5GxgC7kXk').get()
    if (!owner.exists && theOwnerId) {
      userProps.isApplicant = true
      userProps.ownerId = theOwnerId
      owner = await firestore.collection('OWNERS').doc(theOwnerId).collection('SCENARIO_PUBLIC').doc('I50eomEXZFI5GxgC7kXk').get()
    }
    if (owner.data()) {
      const { companyNameEn, companyNameJa } = owner.data()
      if (companyNameJa) {
        userProps.companyName = companyNameJa
      } else if (companyNameEn) {
        userProps.companyName = companyNameEn
      }
    }

    mixpanel.register(userProps)
    mixpanel.people.set(userProps)
    Sentry.configureScope(scope => {
      const { uid, displayName, userAuthEmail, ...rest } = userProps
      const sentryProps = {
        id: uid,
        ...rest
      }

      if (displayName) {
        sentryProps.username = displayName
      }

      if (userAuthEmail) {
        sentryProps.email = userAuthEmail
      }

      scope.setUser(sentryProps)
    })
  }
}

export function registerApplicantName ({ applicantName }) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel
  mixpanel.people.set({
    applicantName
  })
}

export function registerApplicantEmail ({ address }) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel
  mixpanel.people.set({
    $email: address
  })
}

export function registerInterviewDate ({ interviewDateTime }) {
  analyticsEvent(EVENT_NAMES.SCHEDULED_INTERVIEW, {
    interviewDateTime
  })
}

export function registerOwnerEmail ({ address }) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel
  mixpanel.people.set({
    $email: address
  })
}

export function registerOwnerCompanyName ({ companyNameJp }) {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel
  mixpanel.people.set({
    companyNameJp: companyNameJp
  })
}

export function identifyUserPlans (plans) {
  if (plans) {
    window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
    const mixpanel = window.mixpanel

    const analyticsPlans = Object.keys(plans).reduce((memo, key) => {
      memo[`plan${key}`] = plans[key]
      return memo
    }, {})

    if (mixpanel) {
      mixpanel.register({
        ...analyticsPlans
      })
      mixpanel.people.set({
        ...analyticsPlans
      })
    }
  }
}

export function setAsOwner () {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel

  mixpanel.register({
    isOwner: true,
    isGuest: false
  })
}

export function setAsGuest () {
  window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  const mixpanel = window.mixpanel

  mixpanel.register({
    isGuest: true,
    isOwner: false
  })
}

const thirtySec = 1000 * 30

function aliasUserIfNewSignup (user) {
  const createdTimeMS = new Date(user.metadata.creationTime).getTime()
  const differenceMs = new Date().getTime() - createdTimeMS

  if (differenceMs < thirtySec) {
    window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
    const mixpanel = window.mixpanel
    mixpanel.alias(user.uid)
  }
}
