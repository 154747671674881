import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './auth'
import * as data from './data'
import * as calendar from './calendar'
import * as stripe from './stripe'
import * as applicantSearch from './applicantSearch'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: {
      namespaced: true,
      ...auth
    },
    data: {
      namespaced: true,
      ...data
    },
    calendar: {
      namespaced: true,
      ...calendar
    },
    stripe: {
      namespaced: true,
      ...stripe
    },
    applicantSearch: {
      namespaced: true,
      ...applicantSearch
    }
  }
})

if (process.env.VUE_APP_STAGE === 'dev') {
  store.watch(state => ({ uid: state.auth.uid, isAnonymous: state.auth.isAnonymous }), ({ uid, isAnonymous }) => console.log('auth set to:', { uid, isAnonymous })) // eslint-disable-line no-console
}

export default store
