<template>
  <v-app id="app">
    <v-main>
      <v-overlay :value="true" v-if="showSpinner">
        <v-progress-circular
          indeterminate
          size="64"
          class="mx-2"
        ></v-progress-circular
        >読込み中
      </v-overlay>
      <template v-else>
        <SharedToolbar
          :enableSidebar="enableSidebar"
          :title="title"
          @clicked-drawer="drawer = !drawer"
        />
        <NavigationDrawer :enableSidebar="enableSidebar" v-model="drawer" />
        <AssociateQRCodes />
        <router-view />
      </template>
    </v-main>
  </v-app>
</template>

<script>
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import { mapState } from 'vuex'
import SharedToolbar from '@/components/SharedToolbar'
import NavigationDrawer from '@/components/NavigationDrawer'
import AssociateQRCodes from '@/components/AssociateQRCodes'

export default {
  name: 'App',
  components: {
    SharedToolbar,
    NavigationDrawer,
    AssociateQRCodes
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    ...mapState('auth', ['isAuthenticating', 'isSigningOut']),
    showSpinner () {
      return this.isAuthenticating || this.isSigningOut
    },
    enableSidebar () {
      return this.$route.meta.enableSidebar
    },
    title () {
      return this.$route.meta.title
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_APP)
  }
}
</script>

<style>
html,
body {
  margin: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* .upil-select .v-select__slot > .v-label {
  color: white !important;
}
.upil-select .v-select__selection {
  color: white;
}
.upil-multi-select .v-select__slot > .v-label {
  color: white !important;
}
.upil-multi-select .v-select__selection {
  color: white;
}

#app .upil-text-bubble.secondary {
  background-color: #ffe082 !important;
}

#app .upil-text-bubble.primary {
  background-color: #e0e0e0 !important;
}

#app .upil-text-bubble .v-card__text {
  color: #424242 !important;
}

#app .text-node .linkified {
  color: #0000ee;
}

#app
  #bottom-bar
  .v-input__icon.v-input__icon--append-outer
  > .v-icon.primary--text {
  color: #ffe082 !important;
}

#app #bottom-bar .upil-select .v-input__slot.primary {
  background-color: #ffe082 !important;
}

#app #bottom-bar .upil-select .v-input__slot.primary .v-select__selection {
  color: #424242 !important;
}

#app #bottom-bar .upil-select .v-input__slot.primary .v-label {
  color: #424242 !important;
}

#app #bottom-bar .upil-dynamic-list .v-input__slot.primary {
  background-color: #ffe082 !important;
}

#app #bottom-bar .upil-dynamic-list .v-input__slot.primary .v-label {
  color: #424242 !important;
}

#app #bottom-bar .upil-dynamic-list input {
  color: #424242 !important;
}

#app #bottom-bar .upil-multi-select .v-input__slot.primary {
  background-color: #ffe082 !important;
}

#app
  #bottom-bar
  .upil-multi-select
  .v-input__slot.primary
  .v-select__selection {
  color: #424242 !important;
}

#app #bottom-bar .upil-multi-select .v-input__slot.primary .v-label {
  color: #424242 !important;
} */

.v-picker--date .v-date-picker-table .v-btn--disabled .v-btn__content {
  color: rgba(0, 0, 0, 0.1) !important;
}
</style>
