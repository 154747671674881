import * as Sentry from '@sentry/browser'

export const state = () => ({
  data: {},
  loaded: false,
  // A doc ref that should be used when saving data to the db
  saveDocRef: null
})

export const mutations = {
  updateData (state, value) {
    state.data = { ...state.data, ...value }
    state.loaded = true
  },
  updateSaveDocRef (state, value) {
    state.saveDocRef = value
  }
}

export const actions = {
  async saveToDB ({ state }, { location, value }) {
    if (state.saveDocRef) {
      try {
        return state.saveDocRef.set(
          { [location]: value },
          {
            merge: true
          }
        )
      } catch (err) {
        Sentry.captureException(err)
        // eslint-disable-next-line
        console.error('Error saving string to DB: ', err)
      }
    } else {
      const errorMessage = 'Cannot save to DB. Missing saveDocRef!'
      Sentry.captureMessage(errorMessage)
      // eslint-disable-next-line
      console.error(errorMessage)
    }
  }
}
