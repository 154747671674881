import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import '@/plugins/vuemeta'
import '@/plugins/veevalidate'
import '@/plugins/vue-instasearch'
import '@/plugins/vue-upil'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

/**
 * Hide errors caused by redirects in navigation guards
 */
window.addEventListener('unhandledrejection', function (event) {
  const isRouterError = event.reason && event.reason._isRouter
  if (isRouterError) {
    event.preventDefault()
  }
})

if (process.env.VUE_APP_MIXPANEL_DEBUGGING === 'yes') {
  window.mixpanel.set_config({
    debug: true
  })
}

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  release: process.env.VUE_APP_COMMIT_REF,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

// force rebuild
