<template>
  <v-snackbar :timeout="-1" :color="snackbarColor" top v-model="snackbarModel">
    <p v-if="success">
      ステッカー（12枚）のQRコードが、あなたのアカウントに紐付けられ、利用可能な状態になりました。
      ステッカーを貼る場所の名前を、各ステッカーの横（台紙の枠部分）にメモしてから貼ると後ほど確認しやすいですよ😄
    </p>
    <p v-else>
      {{ finalError }}
    </p>
    <template v-slot:action="{ attrs }">
      <v-btn
        large
        icon
        :color="buttonColor"
        v-bind="attrs"
        @click="snackbarModel = false"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

const errorTranslations = {
  'Redirect ID already associated with an owner': 'すでにアカウントに紐付けられているステッカー台紙です。ステッカー部分のQRコードのいずれかを読み込みテストし、あなたのアカウントに紐付いているかを確認してからご利用ください。'
}

export default {
  props: {
    snackbar: {
      type: Boolean,
      required: true
    },
    success: {
      type: Boolean
    },
    error: {
      type: String
    }
  },
  computed: {
    snackbarColor () {
      return this.success ? 'secondary lighten-1' : 'error'
    },
    buttonColor () {
      return this.success ? 'primary' : 'secondary'
    },
    finalError () {
      return errorTranslations[this.error] ? errorTranslations[this.error] : this.error
    },
    snackbarModel: {
      get () {
        return this.snackbar
      },
      set (value) {
        this.$emit('update:snackbar', value)
      }
    }
  }
}
</script>

<style>
</style>
