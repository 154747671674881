import Vue from 'vue'
import VueI18n from 'vue-i18n'
import jaMessages from '../messages/ja'
import enMessages from '../messages/en'

Vue.use(VueI18n)

const browserLocale = navigator.language.split('-')[0]

const messages = {
  ja: { m: { ...jaMessages } },
  en: { m: { ...enMessages } }
}

const i18n = new VueI18n({
  locale: browserLocale || 'ja',
  fallbackLocale: 'ja',
  silentTranslationWarn: true,
  messages
})

export default i18n
