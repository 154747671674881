import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

export const getUserInfo = async (ownerId) => {
  const user = await firestore
    .collection(COLLECTIONS_ENUM.USERS)
    .doc(ownerId)
    .get()

  if (!user.exists) {
    return null
  }

  const { info } = user.data() ? user.data() : null

  return info
}

export const getUserCompany = async (ownerId) => {
  const user = await firestore
    .collection(COLLECTIONS_ENUM.USERS)
    .doc(ownerId)
    .get()

  if (!user.exists) {
    return null
  }

  const { company } = user.data() ? user.data() : null

  return company || ''
}
